/**
 * Support Groups create and update View
 */
import React from 'react';
import { TextKeys } from '@lib/i18n/configureI18n';
import { useTranslation } from 'react-i18next';
import { Card } from '@mui/material';
import { useSelector } from 'react-redux';
import { ReactComponent as PendingSvg } from '@assets/icons/pending.svg';
import { ReactComponent as RejectedSvg } from '@assets/icons/rejected.svg';
import { ReactComponent as VerifiedSvg } from '@assets/icons/verified.svg';
import { ReactComponent as EmptyList } from '@assets/images/empty_list.svg';
import WithSideBar from '@shared/templates/with-side-bar/WithSideBar';
import Tabs from '@shared/components/tabs/Tabs';
import {
  fetchApprovedSupportGroups,
  fetchPendingSupportGroups,
  fetchRejectedSupportGroups,
} from '@data/support-groups/supportGroup.thunk';
import useAppDispatch from '@data/useAppDispatch';
import supportGroupSelector from '@data/support-groups/supportGroup.selector';
import Rescroller from '@shared/components/rescroller/Rescroller';
import RequestGroupCard from '@shared/components/group-card/RequestGroupCard';
import ReviewedGroupCard from '@shared/components/group-card/ReviewedGroupCard';
import SubmittingDialog from '@shared/components/dialogs/submittion-dialog/SubmittingDialog';
import Button from '@mui/material/Button';
import SupportGroupGuidelines from '@view/support-group-requests/components/SupportGroupGuidelines';
import useReviewSupportGroups from './hooks/useReviewSupportGroups';
import { LoadingState } from '../../models/Loading';

const SupportGroupRequestsView: React.FC<unknown> = () => {
  const { t } = useTranslation();
  const tabs = [
    t(TextKeys.pending),
    t(TextKeys.approved),
    t(TextKeys.rejected),
  ];

  const totalNumberOfGroups = useSelector(
    supportGroupSelector.selectPendingGroupsCount,
  );
  const [selectedTab, setSelectedTab] = React.useState(0);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = React.useState<LoadingState[]>([
    LoadingState.IDLE,
    LoadingState.IDLE,
    LoadingState.IDLE,
  ]);
  const [previousTab, setPreviousTab] = React.useState(-1);

  const scroller = React.useRef<HTMLDivElement>(null);

  const pendingRequests = useSelector(
    supportGroupSelector.selectPendingRequests,
  );
  const approvedRequests = useSelector(
    supportGroupSelector.selectApprovedRequests,
  );
  const rejectedRequests = useSelector(
    supportGroupSelector.selectRejectedRequests,
  );

  const { SupportGroupReviewProvider, onSubmit, onReset, reviews, submitting } =
    useReviewSupportGroups();

  React.useEffect(() => {
    onReset();
  }, [onReset, selectedTab]);

  React.useEffect(() => {
    if (!submitting) {
      setLoading([LoadingState.IDLE, LoadingState.IDLE, LoadingState.IDLE]);
      setPreviousTab(-1);
    }
  }, [submitting]);

  React.useEffect(() => {
    if (
      loading[selectedTab] === LoadingState.IDLE &&
      previousTab !== selectedTab
    ) {
      setPreviousTab(selectedTab);
      setLoading(
        loading.map((_, i) =>
          i === selectedTab ? LoadingState.LOADING : loading[i],
        ),
      );
      dispatch(
        [
          fetchPendingSupportGroups,
          fetchApprovedSupportGroups,
          fetchRejectedSupportGroups,
        ][selectedTab]({ page: 1 }),
      )
        .then(() => {
          setLoading(
            loading.map((_, i) =>
              i === selectedTab ? LoadingState.SUCCEEDED : loading[i],
            ),
          );
        })
        .catch(() => {
          setLoading(
            loading.map((_, i) =>
              i === selectedTab ? LoadingState.FAILED : loading[i],
            ),
          );
        });
    }
  }, [dispatch, loading, previousTab, selectedTab]);

  const renderTotalNumberOfGroupsToReview = (): React.ReactNode => (
    <span className="absolute right-0 text-on-secondary subtitle2 top-[5px]">
      {`${totalNumberOfGroups} ${t(TextKeys.totalGroups)}`}
    </span>
  );

  const renderRow = (i) => {
    const request = [pendingRequests, approvedRequests, rejectedRequests][
      selectedTab
    ][i];
    if (selectedTab === 0)
      return (
        <RequestGroupCard
          key={`${selectedTab}-${request.id}`}
          request={request}
        />
      );
    return (
      <ReviewedGroupCard
        key={`${selectedTab}-${request.id}`}
        request={request}
      />
    );
  };

  const getRequestsCount = (): number => {
    return [pendingRequests, approvedRequests, rejectedRequests][selectedTab]
      .length;
  };

  const renderHeader = (): React.ReactNode => {
    const Icon = [PendingSvg, VerifiedSvg, RejectedSvg][selectedTab];
    const text = `${
      [t(TextKeys.pending), t(TextKeys.approved), t(TextKeys.rejected)][
        selectedTab
      ]
    } ${t(TextKeys.review)}`;
    const count = selectedTab === 0 ? `(${getRequestsCount()})` : '';

    return (
      <div className="flex w-full">
        <Icon className="w-5 h-5" />
        <span className="ml-2 h3">{text}</span>
        <span className="ml-1 text-[#FF6E6E] h3">{count}</span>
      </div>
    );
  };

  const renderLoading = (): React.ReactNode => {
    return (
      <div className="flex flex-col w-full h-full">
        {renderHeader()}
        {Array.from(Array(5).keys()).map((i) => (
          <RequestGroupCard key={i} loading />
        ))}
      </div>
    );
  };

  const renderRequests = (): React.ReactNode => {
    if (
      (loading[selectedTab] === LoadingState.SUCCEEDED ||
        loading[selectedTab] === LoadingState.FAILED) &&
      getRequestsCount() === 0
    )
      return (
        <div className="flex flex-col w-full h-full pt-10">
          <EmptyList className="w-full mt-2" />
          <h5 className="w-full mt-2 text-center h5 text-on-secondary">
            You have no{' '}
            {
              [t(TextKeys.pending), t(TextKeys.approved), t(TextKeys.rejected)][
                selectedTab
              ]
            }{' '}
            requests{' '}
          </h5>
        </div>
      );

    return (
      <SupportGroupReviewProvider>
        <Rescroller
          className="w-full"
          components={{
            Header: renderHeader(),
          }}
          renderer={renderRow}
          scroller={scroller}
          hasMore={false}
          itemsCount={getRequestsCount()}
        />
      </SupportGroupReviewProvider>
    );
  };

  return (
    <WithSideBar>
      <SubmittingDialog
        show={submitting}
        message={t(TextKeys.SubmittingReview)}
      />
      <div className="flex justify-center w-full h-full pb-4">
        <div className="relative w-full h-auto mt-5 flex-col-center max-w-[540px]">
          <span className="w-full mb-4 flex-col-center h3 text-on-primary">
            {t(TextKeys.GroupsReview)}
            {selectedTab === 0 && renderTotalNumberOfGroupsToReview()}
          </span>

          <Tabs tabs={tabs} onTabChanged={setSelectedTab} />
          <Card
            className="w-full h-full overflow-y-scroll mt-[30px]"
            ref={scroller}
          >
            <div className="flex justify-center w-full h-full p-5">
              {loading[selectedTab] === LoadingState.LOADING ||
              loading[selectedTab] === LoadingState.IDLE
                ? renderLoading()
                : renderRequests()}
            </div>
          </Card>

          <Button
            disabled={reviews.length === 0}
            onClick={onSubmit()}
            variant="contained"
            color="primary"
            className={`mb-5 mt-5 w-[220px] h-[50px] ${
              selectedTab !== 0 ? 'invisible' : ''
            }`}
          >
            Submit Review
          </Button>
        </div>
        <SupportGroupGuidelines />
      </div>
    </WithSideBar>
  );
};

export default SupportGroupRequestsView;
