import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PlayIcon from '@mui/icons-material/PlayArrow';
import {
  DraftActions,
  DraftContext,
} from '@shared/components/make-post/hooks/useDraftActions';

const MakePostAttachmentView: React.FC<{
  removeAttachment: (index: number) => void;
  attachments: ({ file: File; content: string } | string)[];
}> = ({ attachments, removeAttachment }) => {
  const { setAttachments } = React.useContext(DraftContext) as DraftActions;

  React.useEffect(() => {
    setAttachments(attachments);
  }, [setAttachments, attachments]);

  const getStyle = () => {
    if (attachments?.length === 1) return 'w-[540px] h-[270px]';
    if (attachments?.length === 2) return 'w-[270px] [220px] mr-1';
    if (attachments?.length === 3) return 'w-[180px] h-[200px] mr-1';
    if (attachments?.length > 3) return 'w-[170px] h-[170px] mr-1';
    return '';
  };

  const handleRemoveAttachment = (index: number) => () => {
    removeAttachment(index);
  };

  const renderAttachment = (
    f:
      | {
          file: File;
          content: string;
        }
      | string,
    i: number,
  ) => {
    let type: JSX.Element | null = null;

    if (typeof f === 'string')
      type = <img className="object-cover w-full h-full" src={f} alt="" />;
    else
      type = !f.content.includes('data:video') ? (
        <img className="object-cover w-full h-full" src={f.content} alt="" />
      ) : (
        <div className="w-full h-full">
          <video
            className="object-cover w-full h-full"
            src={URL.createObjectURL(f.file)}
          />
          <PlayIcon className="absolute w-12 h-12 text-white border-2 border-white rounded-full opacity-70 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
        </div>
      );
    // TODO video thumbnail

    return (
      <div className={`relative  ${getStyle()}`}>
        {type}
        <IconButton
          className="absolute bg-white top-2 right-2 opacity-70"
          onClick={handleRemoveAttachment(i)}
        >
          <CloseIcon className="text-black opacity-100" />
        </IconButton>
      </div>
    );
  };

  if (attachments.length > 0)
    return (
      <div className="w-full overflow-x-auto">
        <div className="flex w-fit">
          {attachments.map((a, i) => renderAttachment(a, i))}
        </div>
      </div>
    );

  // return renderLink();
  return null;
};

export default MakePostAttachmentView;
