/**
 * Flagged Posts View
 */
import React from 'react';
import { TextKeys } from '@lib/i18n/configureI18n';
import { useTranslation } from 'react-i18next';
import { Card } from '@mui/material';
import WithSideBar from '@shared/templates/with-side-bar/WithSideBar';
import Tabs from '@shared/components/tabs/Tabs';
import Timeline from '@shared/components/timeline/Timeline';
import { fetchFlaggedPosts } from '@data/timeline/timeline.thunk';
import TimelineSelectors from '@data/timeline/timeline.selector';
import SubmittingDialog from '@shared/components/dialogs/submittion-dialog/SubmittingDialog';
import { TimeLines } from '@data/timeline/timeline.model';
import Button from '@mui/material/Button';
import { AsyncThunk } from '@reduxjs/toolkit';
import useReviewPosts from './hooks/useReviewPosts';

const FlaggedPosts: React.FC<unknown> = () => {
  const { t } = useTranslation();
  const tabs = ['flagged posts', 'reviewed posts'];

  const [selectedTab, setSelectedTab] = React.useState(0);

  const { Provider, onSubmit, submitting, reviews, reset } = useReviewPosts();

  // const totalNumberOfPosts = useSelector(TimelineSelectors.flagged.totalFlaggedPosts);
  // TODO requires API call to get all flagged posts
  // const renderTotalNumberOfPosts = () => (
  //     <span className='absolute right-0 text-on-secondary subtitle2 top-[5px]'>
  //     {`${totalNumberOfPosts} ${t(TextKeys.totalFlaggedPosts)}`}
  // </span>
  // );

  const renderFlaggedPosts = React.useCallback(() => {
    if (submitting) return null;

    return (
      <Provider>
        <Timeline
          timelineFetcher={
            fetchFlaggedPosts as unknown as AsyncThunk<
              never,
              { page: number },
              never
            >
          }
          selector={TimelineSelectors.flagged.selectNotReviewedPostsIds}
          hasMoreSelector={TimelineSelectors.flagged.hasMoreSelector}
          pageSelector={TimelineSelectors.flagged.pageSelector}
          emptyListMessage={t(TextKeys.NoFlaggedPosts)}
          timeline={TimeLines.FLAGGED}
        />
      </Provider>
    );
  }, [Provider, submitting, t]);

  const renderReviewedPostsList = React.useCallback(() => {
    if (submitting) return null;

    return (
      <Provider>
        <Timeline
          timelineFetcher={
            fetchFlaggedPosts as unknown as AsyncThunk<
              never,
              { page: number },
              never
            >
          }
          selector={TimelineSelectors.flagged.selectReviewedPostsIds}
          hasMoreSelector={TimelineSelectors.flagged.hasMoreSelector}
          pageSelector={TimelineSelectors.flagged.pageSelector}
          timeline={TimeLines.REVIEWED_FLAGGED}
        />
      </Provider>
    );
  }, [Provider, submitting]);

  React.useEffect(() => {
    reset();
  }, [reset, selectedTab]);

  return (
    <WithSideBar>
      <div className="flex justify-center w-full h-full pb-4">
        <div className="relative w-full h-auto mt-5 flex-col-center max-w-[540px]">
          <span className="w-full mb-4 flex-col-center h3 text-on-primary">
            {t(TextKeys.ReviewFlaggedPosts)}
          </span>
          <SubmittingDialog
            show={submitting}
            message={t(TextKeys.SubmittingReview)}
          />
          <Tabs tabs={tabs} onTabChanged={setSelectedTab} />
          <Card className="w-full h-full max-w-[540px] mt-[30px]">
            {selectedTab === 0
              ? renderFlaggedPosts()
              : renderReviewedPostsList()}
          </Card>

          <Button
            disabled={reviews.length === 0}
            className={`mb-5 mt-5 w-[220px] h-[50px] ${
              selectedTab === 1 ? 'invisible' : ''
            }`}
            color="primary"
            variant="contained"
            onClick={onSubmit()}
          >
            Submit Review
          </Button>
        </div>
      </div>
    </WithSideBar>
  );
};

export default FlaggedPosts;
