import * as React from 'react';
import parse, { domToReact } from 'html-react-parser';
import { Button, Card, Dialog, LinearProgress } from '@mui/material';
import ContentFeedback from '@view/discover-page/components/ContentFeedback';
import BookmarkContentIcon from '@view/discover-page/components/BookmarkContentIcon';
import { SeriesDetails } from '@data/contents/Contents.model';
import { ReactComponent as KarmaSvg } from '@assets/icons/karma.svg';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import {
  markSeriesAsCompleted,
  moveToNextStep,
} from '@data/contents/Contents.thunk';
import { useNavigate } from 'react-router-dom';
import useAppDispatch from '@data/useAppDispatch';
import Endpoints from '@view/routes/endpoints';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import PdfPreview from '@view/content-preview/components/PdfPreview';

const ArticleView: React.FC<{
  id: string;
  title: string;
  cover: string;
  htmlContent: string;
  contentType: any;
  readTime: string;
  pdfContent?: string;
  articleType: string;
  contentMark?: 'helpful' | 'unhelpful';
  bookmarked: boolean;
  seriesDetails?: SeriesDetails;
}> = ({
  id,
  title,
  cover,
  pdfContent,
  articleType,
  htmlContent,
  contentType,
  readTime,
  contentMark,
  bookmarked,
  seriesDetails,
}) => {
  const covertHtmlToReact = React.useCallback((domNode: any) => {
    if (domNode.type === 'tag' && domNode.name === 'li') {
      // eslint-disable-next-line no-param-reassign
      domNode.attribs.list_order = domNode.attribs.value;
      // eslint-disable-next-line no-param-reassign
      domNode.attribs.class =
        "text-base font-normal leading-6 font-['Roboto'] m-0 ml-2";
      // eslint-disable-next-line no-param-reassign
      domNode.attribs.style = '';
    }
    if (domNode.type === 'tag' && domNode.name === 'p') {
      // eslint-disable-next-line no-param-reassign
      domNode.attribs.class =
        "text-base font-normal leading-6 font-['Roboto'] mb-2";
    }
    if (domNode.type === 'tag' && domNode.name === 'ul') {
      // eslint-disable-next-line no-param-reassign
      domNode.attribs.class = 'mb-2';
      // eslint-disable-next-line no-param-reassign
      domNode.attribs.style = '';
    }
    if (domNode.type === 'tag' && domNode.name === 'body')
      return (
        <div
          style={{
            padding: '0',
          }}
        >
          {domToReact(domNode.children, {
            replace: covertHtmlToReact,
          })}{' '}
        </div>
      );
    if (domNode.name === 'html')
      return (
        <div>
          {domToReact(domNode.children, {
            replace: covertHtmlToReact,
          })}{' '}
        </div>
      );
    if (domNode.name === 'head') return <div />;
    return domNode;
  }, []);

  const renderContent = React.useMemo(() => {
    const options = {
      replace: covertHtmlToReact,
    };

    if (htmlContent != null) {
      const parsed = parse(htmlContent, options) as React.ReactElement<
        any,
        string | React.JSXElementConstructor<any>
      >;
      return parsed;
    }

    return '';
  }, [htmlContent, covertHtmlToReact]);

  const renderSeriesIndicator = React.useCallback(() => {
    if (!seriesDetails?.contentId) return null;

    const progress =
      (seriesDetails?.currentStep / seriesDetails?.noOfSteps) * 100;

    return (
      <div className="flex w-full h-12 pt-2 pb-2 pl-4 pr-4 shadow">
        <div className="flex flex-col justify-center flex-1 pr-3">
          <p className="mb-1 body2 text-on-secondary">
            Progress: Step {seriesDetails?.currentStep}/
            {seriesDetails?.noOfSteps}
          </p>
          <LinearProgress
            className="w-full h-2 rounded-[100px]"
            variant="determinate"
            value={progress}
            classes={{
              colorPrimary: 'bg-secondary-background rounded-[100px]',
              bar: 'bg-success rounded-[100px]',
            }}
          />
        </div>
        <div className="flex items-center justify-center h-8 border border-solid w-[70px] rounded-[6px] border-secondary-background text-on-primary">
          <KarmaSvg className="w-6 h-6" />
          <span className="caption">+{seriesDetails?.karmaPoints}</span>
        </div>
      </div>
    );
  }, [seriesDetails]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const goToNextStep = React.useCallback(() => {
    if (!seriesDetails || !seriesDetails?.nextStepId) return null;
    dispatch(
      moveToNextStep({
        contentId: id,
        seriesId: seriesDetails?.contentId,
      }),
    ).then(() => {
      navigate(
        Endpoints.DISCOVER_VIEW.replace(
          ':contentId',
          seriesDetails?.nextStepId as string,
        ),
      );
    });
    return null;
  }, [seriesDetails, dispatch, id, navigate]);

  const goToPreviousStep = React.useCallback(() => {
    if (!seriesDetails?.contentId) return null;
    if (!seriesDetails || !seriesDetails?.prevStepId) return null;
    navigate(
      Endpoints.DISCOVER_VIEW.replace(
        ':contentId',
        seriesDetails?.prevStepId as string,
      ),
    );
    return null;
  }, [seriesDetails, navigate]);

  const [showKarmaDialog, setShowKarmaDialog] = React.useState(false);

  const renderKarmaDialog = React.useCallback(() => {
    if (!showKarmaDialog) return null;

    return (
      <Dialog
        className="w-full h-full"
        open={showKarmaDialog}
        classes={{
          paper: 'absolute  top-8',
        }}
      >
        <Card className="flex flex-col items-center p-4 justify-evenly w-[350px] h-[300px]">
          <EmojiEventsIcon className="w-10 h-10 text-[#CB333B]" />
          <h3 className="text-center h3 text-on-primary">
            You earned {seriesDetails?.karmaPoints} Karma Points for completing
            this series!
          </h3>
          <p className="text-center body1 text-on-primary">
            We hope this series was useful to you. You can revisit the steps any
            time if you want.
          </p>
          <Button
            onClick={() => {
              navigate(
                Endpoints.DISCOVER_VIEW.replace(
                  ':contentId',
                  seriesDetails?.contentId as string,
                ),
              );
            }}
            className="h-10 text-white button bg-brand-primary w-[200px] rounded-[100px] hover:bg-lighter-background hover:text-brand-primary"
          >
            GREATE!
          </Button>
        </Card>
      </Dialog>
    );
  }, [
    navigate,
    seriesDetails?.contentId,
    seriesDetails?.karmaPoints,
    showKarmaDialog,
  ]);

  const completeSeries = React.useCallback(() => {
    if (!seriesDetails?.contentId) return null;
    if (seriesDetails?.currentStep !== seriesDetails?.noOfSteps) return null;
    dispatch(
      markSeriesAsCompleted({
        seriesId: id,
      }),
    ).then(() => {
      setShowKarmaDialog(true);
    });
    return null;
  }, [seriesDetails, dispatch, id]);

  const renderPagination = React.useCallback(() => {
    if (!seriesDetails?.contentId) return null;
    const isTheLastStep = seriesDetails.currentStep === seriesDetails.noOfSteps;
    return (
      <div className="flex items-center justify-center w-full h-10 mt-1 mb-2">
        {seriesDetails.prevStepId && (
          <Button
            variant="text"
            onClick={goToPreviousStep}
            className="h-full mr-1 button w-[200px] rounded-[100px] hover:bg-lighter-background text-brand-primary"
          >
            previous step
          </Button>
        )}
        {!isTheLastStep && seriesDetails.nextStepId && (
          <Button
            onClick={goToNextStep}
            className="h-full text-white button bg-brand-primary w-[200px] rounded-[100px] hover:bg-lighter-background hover:text-brand-primary"
          >
            Next step
            <ArrowForwardIos className="ml-2 w6 h6" />
          </Button>
        )}
        {isTheLastStep && (
          <Button
            onClick={completeSeries}
            className="h-full text-white button bg-brand-primary w-[200px] rounded-[100px] hover:bg-lighter-background hover:text-brand-primary"
          >
            Complete series
            <CheckCircleOutline className="ml-2 w6 h6" />
          </Button>
        )}
      </div>
    );
  }, [seriesDetails, goToNextStep, goToPreviousStep, completeSeries]);

  return (
    <Card className="flex flex-col w-full h-full overflow-auto sm:w-[66%] md:w-1/2 xl:w-1/3 max-w-[760px]">
      {renderKarmaDialog()}
      <img
        src={cover}
        alt={title}
        className="object-cover w-full h-[29%] max-h-[278px]"
      />
      {renderSeriesIndicator()}
      <div className="relative flex-1 pl-6 pr-6">
        <div className="flex items-center justify-start w-full mt-4 caption text-[#4D565F]">
          <div className="w-4 h-4 mr-2">{contentType.icon()}</div>
          {contentType.value}
          <div className="w-1 h-1 ml-1 mr-1 rounded-[50%] bg-[#4D565F]" />
          {readTime}
          <BookmarkContentIcon contentId={id} bookmarked={bookmarked} />
        </div>
        <p className="mt-4 mb-3 h1 text-on-primary">{title}</p>
        {articleType === 'pdf' ? (
          <PdfPreview
            pdfUrl={pdfContent}
            width={400}
            // icon={contentType.icon}
            // label={contentType.label}
            // title={title}
          />
        ) : (
          <div>{renderContent}</div>
        )}
      </div>
      {renderPagination()}
      <ContentFeedback contentId={id} contentMark={contentMark} />
    </Card>
  );
};

export default ArticleView;
