import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export const enum TextKeys {
  // App selection page
  Chat = 'Chat',
  CreateAnEvent = 'CreateAnEvent',
  EditEvent = 'EditEvent',
  CreateEvent = 'create-event',
  WelcomeToApp = 'WelcomeToApp',
  SelectAppHelper = 'SelectAppHelper',
  SelectCommunityHelper = 'SelectCommunityHelper',
  SelectCardSubmit = 'SelectCardSubmit',
  AppSelectionPageTitle = 'AppSelectionPageTitle',
  CommunitySelectionPageTitle = 'CommunitySelectionPageTitle',
  WhatIsInYourMind = 'WhatIsInYourMind',
  WhoCanSeeThePost = 'WhoCanSeeThePost',
  MyScheduledPosts = 'MyScheduledPosts',
  MyDrafts = 'MyDrafts',
  MyBookmarks = 'MyBookmarks',
  YourOwnGroup = 'YourOwnGroup',
  CreateYourOwn = 'CreateYourOwn',
  Group = 'group',
  CreateYourGroupDescription = 'CreateYourGroupDescription',
  SupportGroups = 'SupportGroups',
  SupportGroupsDescription = 'SupportGroupsDescription',
  ReviewFlaggedPosts = 'ReviewFlaggedPosts',
  totalFlaggedPosts = 'totalFlaggedPosts',
  totalGroups = 'totalGroups',
  SubmittingReview = 'SubmittingReview',
  ReviewPotentialAdverseEvents = 'ReviewPotentialAdverseEvents',
  AdverseEvents = 'AdverseEvents',
  DetectedAE = 'DetectedAE',
  ViewCaseIDS = 'ViewCaseIDS',
  'GroupsReview' = 'GroupsReview',
  'pending' = 'pending',
  'approved' = 'approved',
  'rejected' = 'rejected',
  'review' = 'review',
  'groupsGuidelines' = 'groupsGuidelines',
  'groupsGuidelinesText' = 'groupsGuidelinesText',
  'groupsGuidelinesNote' = 'groupsGuidelinesNote',
  'groupRequestReject' = 'groupRequestReject',
  'groupRequestApprove' = 'groupRequestApprove',
  'groupRequestedBy' = 'groupRequestedBy',
  'AddYourExplanation' = 'AddYourExplanation',
  'Feed' = 'Feed',
  'Events' = 'Events',
  'MyEvents' = 'MyEvents',
  'Recommended' = 'Recommended',
  'YouHaventJoinedAnyEvents' = 'YouHaventJoinedAnyEvents',
  'ThereAreNoUpcomingEvents' = 'ThereAreNoUpcomingEvents',
  'IAgreeToThe' = 'IAgreeToThe',
  'CommunityGuidelines' = 'CommunityGuidelines',
  'Create' = 'Create',
  'CommunityGuidelinesLink' = 'CommunityGuidelinesLink',
  'Logout' = 'Logout',
  'NoCommunities' = 'NoCommunities',
  'Return' = 'Return',
  'Settings' = 'Settings',
  'ViewGroupInfo' = 'ViewGroupInfo',
  'EditGroupSetting' = 'EditGroupSetting',
  'HideGroup' = 'HideGroup',
  'DeleteGroup' = 'DeleteGroup',
  'UnhideGroup' = 'UnhideGroup',
  'CreateGroup' = 'CreateGroup',
  'EditGroup' = 'EditGroup',
  'CreateGroupDescription' = 'CreateGroupDescription',
  'GroupTitle' = 'GroupTitle',
  'GroupDescription' = 'GroupDescription',
  'Cancle' = 'Cancle',
  'Submit' = 'Submit',
  'About' = 'About',
  'InThisGroup' = 'InThisGroup',
  'AttendingThisEvent' = 'AttendingThisEvent',
  'MyCircleOfFriends' = 'MyCircleOfFriends',
  'SayHi' = 'SayHi',
  'YearsOld' = 'YearsOld',
  NoScheduledPosts = 'NoScheduledPosts',
  NoDrafts = 'NoDrafts',
  NoBookmarks = 'NoBookmarks',
  NoFlaggedPosts = 'NoFlaggedPosts',
  New = 'New',
  Notifications = 'Notifications',
  SharingPost = 'SharingPost',
  SchedulingPost = 'SchedulingPost',
  DraftingPost = 'DraftingPost',
  CMSAlert = 'CMSAlert',
  KarmaDialogDescription = 'KarmaDialogDescription',
  KarmaDialogPointsFavorite = 'KarmaDialogPointsFavorite',
  KarmaDialogPointsComment = 'KarmaDialogPointsComment',
  KarmaDialogPointsPost = 'KarmaDialogPointsPost',
  KarmaDialogPointsProgram = 'KarmaDialogPointsProgram',
  ERROR_PAGE_NOT_FOUND = 'ERROR_PAGE_NOT_FOUND',
  SomethingWentWrong = 'Something Went Wrong',
  GO_TO_FEED = 'GO_TO_FEED',
  Refresh = 'Refresh',
  Projects = 'Projects',
  CreateContent = 'CreateContent',
  Hello = 'Hello',
  Welcome = 'Welcome',
}

export const resources: Record<
  string,
  {
    translation: Record<TextKeys, string>;
  }
> = {
  en: {
    translation: {
      [TextKeys.CreateAnEvent]: 'Create an Event',
      [TextKeys.WelcomeToApp]: 'Welcome to the Curatio Admin Panel',
      [TextKeys.SelectAppHelper]: 'Choose your app:',
      [TextKeys.SelectCardSubmit]: 'GO',
      [TextKeys.AppSelectionPageTitle]: 'Curatio Dashboard | Portal',
      [TextKeys.WhatIsInYourMind]: "What's on your mind?",
      [TextKeys.WhoCanSeeThePost]: 'Who can see this post?',
      [TextKeys.MyScheduledPosts]: 'My Scheduled Posts',
      [TextKeys.MyDrafts]: 'My Drafts',
      [TextKeys.MyBookmarks]: 'My Bookmarks',
      [TextKeys.YourOwnGroup]: 'Your own group!',
      [TextKeys.CreateYourOwn]: 'CREATE YOUR OWN',
      [TextKeys.Group]: 'GROUP',
      [TextKeys.totalFlaggedPosts]: 'Posts to review',
      [TextKeys.totalGroups]: 'Groups to review',
      [TextKeys.SupportGroups]: 'Support Groups',
      [TextKeys.ReviewFlaggedPosts]: 'Review Flagged Posts',
      [TextKeys.SubmittingReview]: 'Submitting review...',
      [TextKeys.DetectedAE]: 'The system thinks this is an adverse event',
      [TextKeys.AdverseEvents]: 'AdverseEvents',
      [TextKeys.ViewCaseIDS]: 'View Case IDs',
      [TextKeys.GroupsReview]: 'Groups Review',
      [TextKeys.ReviewPotentialAdverseEvents]:
        'Review Potential Adverse Events',
      [TextKeys.CreateYourGroupDescription]:
        'Have any amazing idea for a group? Please send in a request. Your application will be reviewed shortly by a Community Manager.',
      [TextKeys.SupportGroupsDescription]:
        'Groups are for like-minded people to share their common interests and express their opinion. People can come together around a common cause, issue, post photos, etc.',
      [TextKeys.pending]: 'Pending',
      [TextKeys.approved]: 'Approved',
      [TextKeys.rejected]: 'Rejected',
      [TextKeys.review]: 'Review',
      [TextKeys.AddYourExplanation]: 'Add your explanation',
      [TextKeys.groupRequestReject]: 'REJECT GROUP',
      [TextKeys.groupRequestApprove]: 'APPROVE GROUP',
      [TextKeys.groupsGuidelines]: 'Groups Guidelines',
      [TextKeys.groupsGuidelinesText]:
        "In order to accept a group request, please ensure that the title, description, and image of the group are appropriate and adhere to Curatio's Community Guidelines. If you reject this request, you'll need to provide a brief explanation for its rejection.",
      [TextKeys.groupsGuidelinesNote]:
        'Note that the user who has requested the group will act as the group administrator. The group administrator will review requests to join the group.',
      [TextKeys.groupRequestedBy]: 'Group requested by',
      [TextKeys.Feed]: 'Feed',
      [TextKeys.Events]: 'Events',
      [TextKeys.CreateEvent]: 'Create Event',
      [TextKeys.MyEvents]: 'My Events',
      [TextKeys.Recommended]: 'Recommended',
      [TextKeys.YouHaventJoinedAnyEvents]: "You haven't joined any events.",
      [TextKeys.ThereAreNoUpcomingEvents]:
        'There are currently no upcoming events. Please check back later ' +
        'or feel free to create your own.',
      [TextKeys.IAgreeToThe]: 'I agree to the',
      [TextKeys.CommunityGuidelines]: 'Community Guidelines',
      [TextKeys.Create]: 'Create',
      [TextKeys.CommunityGuidelinesLink]:
        'https://legal.curatio.me/community-guidelines',
      [TextKeys.CommunitySelectionPageTitle]:
        'Curatio Dashboard | Community Selection',
      [TextKeys.SelectCommunityHelper]: 'Choose your community:',
      [TextKeys.Logout]: 'Log out',
      [TextKeys.NoCommunities]:
        'There doesn’t seem to be any available communities for this account. Please try using another login or selecting a different app.',
      [TextKeys.Return]: 'Return',
      [TextKeys.Settings]: 'Settings',
      [TextKeys.ViewGroupInfo]: 'View Group Info',
      [TextKeys.EditGroupSetting]: 'Edit Group Setting',
      [TextKeys.HideGroup]: 'Hide Group',
      [TextKeys.DeleteGroup]: 'Delete Group',
      [TextKeys.UnhideGroup]: 'Unhide Group',
      [TextKeys.CreateGroup]: 'Create Group',
      [TextKeys.EditGroup]: 'Edit Group',
      [TextKeys.CreateGroupDescription]:
        'Your request will be evaluated by our team. Soon we will communicate to you if your group request was approved.',
      [TextKeys.GroupTitle]: 'Group Title',
      [TextKeys.GroupDescription]: 'Group Description',
      [TextKeys.Cancle]: 'Cancle',
      [TextKeys.Submit]: 'Submit',
      [TextKeys.About]: 'About',
      [TextKeys.InThisGroup]: 'In this group',
      [TextKeys.AttendingThisEvent]: 'Attending this event',
      [TextKeys.MyCircleOfFriends]: 'My Circle of Friends',
      [TextKeys.SayHi]: 'Say Hi',
      [TextKeys.YearsOld]: 'years old',
      [TextKeys.Chat]: 'Chat',
      [TextKeys.NoScheduledPosts]: 'You have no scheduled posts.',
      [TextKeys.NoDrafts]: 'You have no drafts.',
      [TextKeys.NoBookmarks]: 'You have no bookmarks.',
      [TextKeys.NoFlaggedPosts]: 'No flagged posts is reported.',
      [TextKeys.New]: 'New',
      [TextKeys.Notifications]: 'Notifications',
      [TextKeys.SharingPost]: 'Sharing your post...',
      [TextKeys.SchedulingPost]: 'Scheduling your post...',
      [TextKeys.DraftingPost]: 'Saving your post...',
      [TextKeys.CMSAlert]:
        'The following fields are required to be completed before publishing. ' +
        'Please review your content and try again.',
      [TextKeys.KarmaDialogDescription]:
        'We recognize your contributions to the community. Here’s how you earn them:',
      [TextKeys.KarmaDialogPointsComment]: 'Comment',
      [TextKeys.KarmaDialogPointsFavorite]: 'Cheer',
      [TextKeys.KarmaDialogPointsPost]: 'Post',
      [TextKeys.KarmaDialogPointsProgram]: 'Program completion',
      [TextKeys.ERROR_PAGE_NOT_FOUND]:
        'The page you are looking for might have been removed or temporarily unavailable. We’re working super hard to fix that.',
      [TextKeys.GO_TO_FEED]: 'Go to Feed',
      [TextKeys.SomethingWentWrong]:
        'Something went wrong. Please try again later.',
      [TextKeys.Refresh]: 'Refresh Page',
      [TextKeys.Projects]: 'Projects',
      [TextKeys.EditEvent]: 'Edit Event',
      [TextKeys.CreateContent]: 'Create Content',
      [TextKeys.Hello]: 'Hello,',
      [TextKeys.Welcome]:
        'Welcome to Discover! Explore suggested content and uncover new insights about managing your health.',
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',
  });

export default i18n;
