import { createAsyncThunk } from '@reduxjs/toolkit';

import { FileUtils, ParamsUtils, URLUtils } from '@utils/index';
import createAsyncThunkWithAuth from '@data/createAsyncThunkWithAuth';
import axios from 'axios';
import { ApiEndpoint } from '@utils/types';

export const fetchSupportGroups = createAsyncThunkWithAuth<{ page: number }>(
  'supportGroup/fetchSupportGroups',
  async (args, urlGenerator) => {
    const myGroups = await axios.get(
      urlGenerator(ApiEndpoint.FETCH_MY_GROUPS, {
        user: args.userId,
        page: args.page,
      }),
    );
    const allGroups = await axios.get(
      urlGenerator(ApiEndpoint.FETCH_ALL_GROUPS, {
        user: args.userId,
        page: args.page,
      }),
    );
    return {
      allGroups: allGroups.data,
      myGroups: myGroups.data,
      userId: args.userId,
    };
  },
);

export const fetchMySupportGroups = createAsyncThunkWithAuth<{ page: number }>(
  'supportGroup/fetchSupportGroups',
  async (args, urlGenerator) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          urlGenerator(ApiEndpoint.FETCH_MY_GROUPS, {
            user: args.userId,
            page: args.page,
          }),
        )
        .then((response) => {
          resolve({
            allGroups: response.data,
            myGroups: response.data,
            userId: args.userId,
          });
        })
        .catch(reject);
    });
  },
);

export const fetchPendingSupportGroups = createAsyncThunkWithAuth<{
  page: number;
}>('supportGroup/fetchPendingSupportGroups', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        urlGenerator(ApiEndpoint.GROUP_REVIEW_FEED, {
          user: args.userId,
          page: args.page,
        }),
        {
          params: {
            condition: args.community,
            device_id: args.deviceId,
            status: 'pending',
            all: true,
          },
        },
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch(reject);
  });
});

export const fetchApprovedSupportGroups = createAsyncThunkWithAuth<{
  page: number;
}>('supportGroup/fetchApprovedSupportGroups', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        urlGenerator(ApiEndpoint.GROUP_REVIEW_FEED, {
          user: args.userId,
          page: args.page,
        }),
        {
          params: {
            condition: args.community,
            device_id: args.deviceId,
            status: 'approved',
            all: true,
          },
        },
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch(reject);
  });
});
export const fetchRejectedSupportGroups = createAsyncThunkWithAuth<{
  page: number;
}>('supportGroup/fetchRejectedSupportGroups', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        urlGenerator(ApiEndpoint.GROUP_REVIEW_FEED, {
          user: args.userId,
          page: args.page,
        }),
        {
          params: {
            condition: args.community,
            device_id: args.deviceId,
            status: 'rejected',
            all: true,
          },
        },
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch(reject);
  });
});

const submitSupportGroupReview = createAsyncThunk(
  'supportGroup/submitSupportGroupReview',
  async ({
    review,
    url,
  }: {
    review: {
      id: number;
      status: 'approved' | 'rejected';
      comment?: string;
    };
    url: string;
  }) => {
    return new Promise((resolve, reject) => {
      axios
        .put(
          url,
          ParamsUtils.jsonToForm({
            is_approved: String(review.status === 'approved'),
            reviewer_message: review.comment,
          }),
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
);

export const submitSupportGroupReviews = createAsyncThunkWithAuth<{
  reviews: { id: number; status: 'approved' | 'rejected' }[];
}>(
  'supportGroup/submitSupportGroupReviews',
  async (args, urlGenerator, dispatch) => {
    return Promise.all(
      args.reviews.map((review) =>
        dispatch(
          submitSupportGroupReview({
            review,
            url: urlGenerator(ApiEndpoint.REVIEW_GROUP, {
              user: args.userId,
              id: review.id,
            }),
          }),
        ),
      ),
    );
  },
);

export const fetchSupportGroupMembers = createAsyncThunkWithAuth<{
  groupId: string;
  numberOfMembers?: number;
}>('supportGroup/fetchSupportGroupMembers', async (args, urlGenerator) => {
  const responses = await Promise.all(
    [...new Array(Math.ceil((args.numberOfMembers || 50) / 50))].map(
      async (_, index) => {
        const response = await axios.get(
          urlGenerator(ApiEndpoint.FETCH_GROUP_MEMBERS, {
            user: args.userId,
            groupId: args.groupId,
            page: index + 1,
          }),
        );
        return response.data;
      },
    ),
  );
  return {
    members: responses.flat(),
    userId: args.userId,
  };
});

export const joinSupportGroup = createAsyncThunkWithAuth<{ groupId: string }>(
  'supportGroup/joinSupportGroup',
  async (args, urlGenerator) => {
    const response = await axios.post(
      urlGenerator(ApiEndpoint.JOIN_OR_LEAVE_GROUP, {
        user: args.userId,
        groupId: args.groupId,
      }),
    );
    return response.data;
  },
);

export const leaveSupportGroup = createAsyncThunkWithAuth<{ groupId: string }>(
  'supportGroup/leaveSupportGroup',
  async (args, urlGenerator) => {
    const response = await axios.delete(
      urlGenerator(ApiEndpoint.JOIN_OR_LEAVE_GROUP, {
        user: args.userId,
        groupId: args.groupId,
      }),
    );
    return response.data;
  },
);

export const createSupportGroup = createAsyncThunkWithAuth<{
  title: string;
  description: string;
  cover: string;
}>('supportGroup/createSupportGroup', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    FileUtils.localURLtoFile(args.cover, args.title)
      .then((cover) => {
        const form = ParamsUtils.jsonToForm({
          title: args.title,
          description: args.description,
          is_private: String(false),
          request_type: 'create',
        });

        form.append('image', cover);
        axios
          .post(
            URLUtils.generateApiRoute(ApiEndpoint.CREATE_GROUP, {
              user: args.userId,
            }),
            form,
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch(reject);
      })
      .catch(reject);
  });
});

export const updateSupportGroup = createAsyncThunkWithAuth<{
  groupId: string;
  title: string;
  description: string;
  cover: string;
}>('supportGroup/updateSupportGroup', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    FileUtils.localURLtoFile(args.cover, args.title).then((cover) => {
      const form = ParamsUtils.jsonToForm({
        title: args.title,
        description: args.description,
        is_private: String(false),
        request_type: 'update',
      });

      form.append('image', cover);
      axios
        .put(
          urlGenerator(ApiEndpoint.UPDATE_GROUP, {
            user: args.userId,
            groupId: args.groupId,
          }),
          form,
        )
        .then((response) => {
          resolve({ group: response.data, userId: args.userId });
        })
        .catch(reject);
    });
  });
});

export const hideSupportGroup = createAsyncThunkWithAuth<{ groupId: string }>(
  'supportGroup/hideSupportGroup',
  async (args, urlGenerator) => {
    return new Promise((resolve, reject) => {
      axios
        .put(
          urlGenerator(ApiEndpoint.HIDE_GROUP, {
            user: args.userId,
            groupId: args.groupId,
          }),
        )
        .then((response) => {
          resolve({ group: response.data, userId: args.userId });
        })
        .catch(reject);
    });
  },
);

export const unhideSupportGroup = createAsyncThunkWithAuth<{ groupId: string }>(
  'supportGroup/unhideSupportGroup',
  async (args, urlGenerator) => {
    return new Promise((resolve, reject) => {
      axios
        .put(
          urlGenerator(ApiEndpoint.UNHIDE_GROUP, {
            user: args.userId,
            groupId: args.groupId,
          }),
        )
        .then((response) => {
          resolve({ group: response.data, userId: args.userId });
        })
        .catch(reject);
    });
  },
);
export const deleteSupportGroup = createAsyncThunkWithAuth<{ groupId: string }>(
  'supportGroup/deleteSupportGroup',
  async (args, urlGenerator) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          urlGenerator(ApiEndpoint.DELETE_GROUP, {
            user: args.userId,
            groupId: args.groupId,
          }),
        )
        .then((response) => {
          resolve({ group: response.data, userId: args.userId });
        })
        .catch(reject);
    });
  },
);
export const fetchSupportGroup = createAsyncThunkWithAuth<{
  groupId: string;
  userId?: number;
}>('supportGroup/fetchSupportGroup', async (args, urlGenerator) => {
  const response = await axios.get(
    urlGenerator(ApiEndpoint.GET_GROUP, {
      user: args.userId,
      groupId: args.groupId,
    }),
  );
  return response.data;
});
