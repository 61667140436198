import * as React from 'react';
import { Button, Card, LinearProgress } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { ContentStep, ContentTypes } from '@data/contents/Contents.model';
import LockIcon from '@mui/icons-material/Lock';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';

import { useNavigate } from 'react-router-dom';
import Endpoints from '@view/routes/endpoints';
import ContentFeedback from '@view/discover-page/components/ContentFeedback';
import BookmarkContentIcon from '@view/discover-page/components/BookmarkContentIcon';
import { startSeries } from '@data/contents/Contents.thunk';
import { useDispatch } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { getContentTypeView } from '../../../logic/CMS/content.logic';

const SeriesView: React.FC<{
  id?: string;
  cover?: string;
  title?: string;
  contentType?: {
    icon: () => React.ReactNode;
    value: string;
  };
  numOfSteps?: number;
  description?: string;
  steps: ContentStep[];
  isStarted?: boolean;
  numOfCompletedSteps?: number;
  contentMark?: 'helpful' | 'unhelpful';
  bookmarked?: boolean;
}> = ({
  id,
  cover,
  title,
  contentType,
  numOfSteps,
  description,
  steps,
  isStarted,
  numOfCompletedSteps,
  contentMark,
  bookmarked,
}) => {
  const navigate = useNavigate();

  const navigateToStep = React.useCallback(
    (stepId: string) => () => {
      navigate(Endpoints.DISCOVER_VIEW.replace(':contentId', stepId));
    },
    [navigate],
  );
  const dispatch = useDispatch();
  const start = React.useCallback(() => {
    dispatch(startSeries({ id }));
  }, [dispatch, id]);

  const renderSteps = React.useCallback(() => {
    const stepType = getContentTypeView(ContentTypes.ARTICLE);
    return (
      <div className="flex-1 pl-6 pr-6 overflow-auto">
        {steps.map((step, index) => {
          const completed = isStarted && numOfCompletedSteps > index;
          const canViewStep = isStarted && numOfCompletedSteps === index;
          return (
            <IconButton
              key={`series-${id}-step-${index}`}
              disabled={!canViewStep && !completed}
              onClick={navigateToStep(step.id)}
              className={`flex items-center justify-start w-full p-4 h-[112px] rounded-[8px]
              ${numOfCompletedSteps === index && 'bg-[#00AAD3]/10'}
              `}
            >
              <img
                src={step.cover}
                alt={step.title}
                className="object-cover w-24 h-24 rounded-[8px]"
              />
              <div className="flex flex-col justify-center flex-1 h-full ml-4">
                <h3 className=" items-center w-full text-left h3 text-on-primary ellipsis2">
                  {step.title}
                </h3>
                <div className="flex items-center justify-start w-full mt-2 caption text-[#4D565F]">
                  <div className="w-4 h-4 mr-2">{stepType.icon()}</div>
                  {stepType.value}
                  <div className="w-1 h-1 ml-1 mr-1 rounded-[50%] bg-[#4D565F]" />
                  {step.readTime}
                </div>
              </div>
              {completed && <CheckCircleIcon className="w-5 text-success" />}
              {!completed &&
                (canViewStep ? (
                  <ArrowForwardIcon className="w-5 h-4 text-on-secondary" />
                ) : (
                  <LockIcon className="w-5 text-on-secondary" />
                ))}
            </IconButton>
          );
        })}
      </div>
    );
  }, [id, isStarted, navigateToStep, numOfCompletedSteps, steps]);

  return (
    <Card className="w-full h-full sm:w-[66%] md:w-1/2 xl:w-1/3 max-w-[760px]">
      <div className="flex flex-col h-full">
        <img
          src={cover}
          alt={title}
          className="object-cover w-full h-[20%] max-h-[260px]"
        />
        <LinearProgress
          className="w-full h-2"
          variant="determinate"
          value={(numOfCompletedSteps / numOfSteps) * 100}
          classes={{
            colorPrimary: 'bg-secondary-background',
            bar: 'bg-success',
          }}
        />
        <div className="relative pl-6 pr-6">
          <div className="flex items-center justify-start w-full mt-4 caption text-[#4D565F]">
            <div className="w-4 h-4 mr-2">{contentType.icon()}</div>
            {contentType.value}
            <div className="w-1 h-1 ml-1 mr-1 rounded-[50%] bg-[#4D565F]" />
            {numOfSteps} steps
            <BookmarkContentIcon contentId={id} bookmarked={bookmarked} />
          </div>
          <p className="mt-4 mb-2 h1 text-on-primary">{title}</p>
          <p className="mb-3 body1 text-on-primary">{description}</p>
          {!isStarted && (
            <div className="w-full h-10 mt-1 mb-2 flex-col-center">
              <Button
                onClick={start}
                className="h-full text-white button bg-brand-primary w-[200px] rounded-[100px] hover:bg-lighter-background hover:text-brand-primary"
              >
                Start Series
              </Button>
            </div>
          )}
        </div>
        {renderSteps()}
        <ContentFeedback contentId={id} contentMark={contentMark} />
      </div>
    </Card>
  );
};

export default SeriesView;
