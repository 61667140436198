import React from 'react';
import MakePostDialog from '@shared/components/make-post/MakePostDialog';
import { MakePostDialogOpeningModeAction } from '@shared/components/make-post/models/MakePostAction';
import { TimeLines } from '@data/timeline/timeline.model';
import useDraftActions, {
  DraftContext,
} from '@shared/components/make-post/hooks/useDraftActions';
import useDefaultValue, {
  DefaultValueContext,
} from '@shared/components/make-post/hooks/useDefaultValue';
import SchedulePostDialog from '@shared/components/make-post/components/schedule-post-dialog/SchedulePostDialog';
import { Dialog } from '@mui/material';
import { TextKeys } from '@lib/i18n/configureI18n';
import { useTranslation } from 'react-i18next';
import useBreakpoint from '@hooks/use-breakpoint/useBreakpoints';
import { isMobileBreakpoint } from '@utils/breakpoints.utils';
import SubmittingDialog from '../dialogs/submittion-dialog/SubmittingDialog';

enum STATUS {
  WRITING = 'WRITING',
  POSTING = 'POSTING',
  DRAFTING = 'DRAFTING',
  SCHEDULING = 'SCHEDULING',
}

const MakePostDialogProvider: React.FC<{
  closeDialog: () => void;
  open: MakePostDialogOpeningModeAction;
  channelId?: number;
  timeline?: TimeLines;
}> = ({ closeDialog, open, channelId, timeline }) => {
  const [draftFeedActions, draftShareActions] = useDraftActions(
    open?.id?.split('-')[1],
    channelId,
    timeline,
  );

  const { t } = useTranslation();

  const defaultDraftValue = useDefaultValue(open.id);

  const [showSchedulingDialog, setShowSchedulingDialog] = React.useState(false);

  const [status, setStatus] = React.useState<STATUS>(STATUS.WRITING);

  const { breakpoint } = useBreakpoint();

  const performAction = React.useCallback(
    async (promise: Promise<any>) => {
      promise
        .then(() => {
          closeDialog();
          setStatus(STATUS.WRITING);
        })
        .catch(() => {
          setStatus(STATUS.WRITING);
        });
    },
    [closeDialog],
  );

  const contextValue = React.useMemo(
    () => ({
      ...draftFeedActions,
      onShare: async () => {
        if (!draftShareActions.onValidate()) return;
        setStatus(STATUS.POSTING);
        await performAction(draftShareActions.onShare());
      },
      onDraft: async () => {
        if (!draftShareActions.onValidate()) return;
        setStatus(STATUS.DRAFTING);
        await performAction(draftShareActions.onDraft());
      },
      onSchedule: async () => {
        if (!draftShareActions.onValidate()) return;
        setStatus(STATUS.SCHEDULING);
        await performAction(draftShareActions.onSchedule());
      },
      onStartScheduling: () => {
        if (!draftShareActions.onValidate()) return;
        setShowSchedulingDialog(true);
      },
    }),
    [draftFeedActions, draftShareActions, performAction],
  );

  const renderDialogContent = () => {
    if (status !== STATUS.WRITING) {
      return <SubmittingDialog message={t(TextKeys.SharingPost)} />;
    }

    if (showSchedulingDialog)
      return (
        <SchedulePostDialog
          isOpen={showSchedulingDialog}
          onClose={() => () => setShowSchedulingDialog(false)}
        />
      );

    return <MakePostDialog open={open?.mode} closeDialog={closeDialog} />;
  };

  const getStatusMessage = React.useCallback(
    (status: STATUS, t: (key: string) => string) => {
      switch (status) {
        case STATUS.DRAFTING:
          return t(TextKeys.DraftingPost);
        case STATUS.POSTING:
          return t(TextKeys.SharingPost);
        case STATUS.SCHEDULING:
          return t(TextKeys.SchedulingPost);
        default:
          return '';
      }
    },
    [],
  );

  return (
    <DraftContext.Provider value={contextValue}>
      <DefaultValueContext.Provider value={defaultDraftValue}>
        <SubmittingDialog
          message={getStatusMessage(status, t)}
          show={status !== STATUS.WRITING}
        />
        <Dialog
          open={open?.mode !== 'close'}
          classes={{
            container:
              'lg:ml-[60px] flex items-center sm:items-start sm:pt-6 sm:m-0 sm:p-0 sm:w-full sm:h-full pr-0 sm:pr-[70px]  overflow-hidden ',
            paper: 'mt-0 sm:!mt-5 mr-0 ml-0 mb-0 h-auto max-h-full',
          }}
          fullScreen={isMobileBreakpoint(breakpoint)}
        >
          {renderDialogContent()}
        </Dialog>
      </DefaultValueContext.Provider>
    </DraftContext.Provider>
  );
};

export default MakePostDialogProvider;
