// @flow
import * as React from 'react';
import { PropsWithChildren } from 'react';
import useAppDispatch from '@data/useAppDispatch';
import { submitSupportGroupReviews } from '@data/support-groups/supportGroup.thunk';
import { ReviewSupportGroupsContext } from '../context/ReviewSupportGroupsContext';

type useReviewSupportGroupsType = {
  SupportGroupReviewProvider: React.FC<PropsWithChildren>;
  reviews: { status: 'approved' | 'rejected'; id: number; comment?: string }[];
  onSubmit: () => () => void;
  onReset: () => void;
  submitting: boolean;
};

const useReviewSupportGroups = (): useReviewSupportGroupsType => {
  const [reviews, setReviews] = React.useState<
    { status: 'approved' | 'rejected'; id: number; comment?: string }[]
  >([]);
  const [review, setReview] = React.useState<
    | {
        status: 'approved' | 'rejected' | 'remove';
        id: number;
        comment?: string;
      }
    | undefined
  >(undefined);
  const [status, setStatus] = React.useState<
    'submitting' | 'pending' | 'done' | 'reviewing'
  >('submitting');

  const dispatch = useAppDispatch();

  const onApprove = React.useCallback(
    (id: number) => {
      setReview({ status: 'approved', id });
    },
    [setReview],
  );

  const onReject = React.useCallback(
    (id: number, comment: string) => {
      setReview({ status: 'rejected', id, comment });
    },
    [setReview],
  );

  React.useEffect(() => {
    if (review) {
      if (review.status === 'remove') {
        setReviews(reviews.filter((r) => r.id !== review.id));
      } else {
        setReviews([
          ...reviews.filter((r) => r.id !== review.id),
          review as {
            status: 'approved' | 'rejected';
            id: number;
            comment?: string;
          },
        ]);
      }
      setReview(undefined);
    }
  }, [review, reviews]);

  React.useEffect(() => {
    if (status === 'submitting') {
      setStatus('pending');
      dispatch(submitSupportGroupReviews({ reviews })).then(() => {
        setStatus('done');
      });
    }
    if (status === 'done') {
      setReviews([]);
      setStatus('reviewing');
    }
  }, [dispatch, reviews, status]);

  const supportGroupReviewValue = React.useMemo(
    () => ({
      onApprove,
      onReject,
    }),
    [onApprove, onReject],
  );

  const SupportGroupReviewProvider = React.useMemo(() => {
    const Component: React.FC<PropsWithChildren> = ({ children }) => (
      <ReviewSupportGroupsContext.Provider value={supportGroupReviewValue}>
        {children}
      </ReviewSupportGroupsContext.Provider>
    );

    return Component;
  }, [supportGroupReviewValue]);

  return {
    SupportGroupReviewProvider,
    reviews,
    onSubmit: () => () => setStatus('submitting'),
    onReset: () => setStatus('reviewing'),
    submitting: status === 'submitting' || status === 'pending',
  };
};

export default useReviewSupportGroups;
