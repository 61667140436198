import { Content, ContentStep, ContentTypes, Tag } from './Contents.model';

const getContentType = (contentType: string): ContentTypes => {
  switch (contentType) {
    case 'ContentItem::Article':
      return ContentTypes.ARTICLE;
    // case 'audio':
    //   return ContentTypes.AUDIO;
    // case 'poll':
    //   return ContentTypes.POLL;
    case 'ContentItem::Series':
      return ContentTypes.SERIES;
    // case 'survey':
    //   return ContentTypes.SURVEY;
    // case 'video':
    //   return ContentTypes.VIDEO;
    default:
      return ContentTypes.ARTICLE;
  }
};

export const convertContentStepsResponse = (response: any): ContentStep => {
  return {
    id: response.id,
    title: response.title,
    cover: response.cover_url,
    type: getContentType(response.type),
    readTime: response.read_time,
  };
};
export const convertContentResponse = (response: any): Content => {
  return {
    id: response.id,
    title: response.title,
    cover: response.cover_url,
    createdAt: response.created_at,
    updatedAt: response.updated_at,
    publishedAt: response.updated_at,
    bookmarked: response.bookmarked,
    type: getContentType(response.type),
    status: response.pub_status,
    htmlContent: response.html_content?.content,
    noOfSteps: response.no_of_steps,
    noOfCompletedSteps: response.no_of_steps_completed,
    pdfContent: response.article_content_url,
    articleType:
      response.article_content_type === 'application/pdf' ? 'pdf' : 'html',
    interaction: {
      numberOfViews: response.view_count,
      numberOfLikes: response.helpful_count,
    },
    category: response.category_name,
    steps: response.steps?.map((step: any) =>
      convertContentStepsResponse(step.content_item),
    ),
    tags: response.tags,
    description: response.summary || response.short_description,
    owner: response.owner,
    name: response.name,
    disclosure: response.disclosure_statement,
    readTime: response.read_time,
    karmaPoints: response.karma_point_reward,
    isSeriesStarted: response.series_started,
    seriesDetails: {
      contentId: response.series_details?.series_id,
      currentStep: response.series_details?.current_step,
      karmaPoints: response.series_details?.karma_point_reward,
      nextStepId: response.series_details?.next_content_id,
      noOfSteps: response.series_details?.no_of_steps,
      noOfCompletedSteps: response.series_details?.no_of_steps_completed,
      prevStepId: response.series_details?.prev_content_id,
    },
    contentMark:
      // eslint-disable-next-line no-nested-ternary
      response.marked_helpful !== null
        ? response.marked_helpful
          ? 'helpful'
          : 'unhelpful'
        : undefined,
  };
};
export const convertContentsListResponse = (response: any[]): Content[] => {
  return response.map((content) => {
    return convertContentResponse(content.content_item);
  });
};

export const convertTagResponse = (response: any): Tag => {
  return {
    id: response.id,
    name: response.title || response.name,
    description: response.short_description,
  };
};

export const convertTagsResponse = (response: any[]): Tag[] => {
  return response.map((tag) => {
    return convertTagResponse(tag);
  });
};
