// @flow
import * as React from 'react';
import { Card } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { Content } from '@data/contents/Contents.model';
import parse, { domToReact } from 'html-react-parser';
import PdfPreview from '@view/content-preview/components/PdfPreview';
import { renderToString } from 'react-dom/server';
import useContent from '@view/content-management-system/hooks/useContent';

const ArticlePreview: React.FC<{
  content: Content;
  width: number;
  height: number;
}> = ({ content, width, height }) => {
  const { publishedAt, views, likes, cover, htmlContent, contentType } =
    useContent(content);
  const MAGNIFY = content.articleType === 'pdf' ? 1.5 : 1;
  const renderInteractionDetails = React.useCallback(() => {
    return (
      <div
        style={{
          marginTop: '12px',
          fontFamily: 'Roboto',
          fontSize: '0.875 rem',
          fontWeight: 400,
        }}
      >
        {publishedAt}{' '}
        <CircleIcon
          style={{
            width: '4px',
            height: '4px',
          }}
        />
        <VisibilityIcon
          style={{
            width: '12px',
            marginLeft: '4px',
            marginRight: '4px',
          }}
        />
        {views}
      </div>
    );
  }, [publishedAt, views]);

  const renderLikes = React.useCallback(() => {
    return (
      <div
        style={{
          marginTop: '20px',
          fontFamily: 'Roboto',
          fontSize: '0.875 rem',
          fontWeight: 400,
          color: 'rgb(53 151 141 / var(--tw-text-opacity))',
        }}
      >
        <ThumbUpIcon
          style={{
            width: '12px',
            height: '12px',
          }}
        />{' '}
        {likes}
      </div>
    );
  }, [likes]);

  const renderCover = React.useCallback(() => {
    return (
      <img
        src={cover}
        style={{
          width: `${width}px`,
          marginLeft: '-16px',
          height: 'auto',
          marginTop: '32px',
          marginBottom: '16px',
          padding: '-10px',
        }}
      />
    );
  }, [cover, width]);

  const covertHtmlToReact = React.useCallback(
    (domNode: any) => {
      if (domNode.type === 'tag' && domNode.name === 'li') {
        // eslint-disable-next-line no-param-reassign
        domNode.attribs.list_order = domNode.attribs.value;
      }
      if (domNode.type === 'tag' && domNode.name === 'body')
        return (
          <body>
            <div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  marginBottom: '8px',
                  fontFamily: 'Roboto',
                  fontSize: '0.875rem',
                  fontWeight: 400,
                  lineHeight: '1.25rem',
                  letterSpacing: '0.08em',
                }}
              >
                <div
                  style={{
                    width: '14px',
                    height: '14px',
                    marginRight: '8px',
                  }}
                >
                  {contentType.icon()}
                </div>
                {contentType.label}
              </div>
              <h1>{content?.title}</h1>
              {renderInteractionDetails()}
              {renderLikes()}
              {renderCover()}
            </div>
            {domToReact(domNode.children, {
              replace: covertHtmlToReact,
            })}{' '}
          </body>
        );
      // if (domNode.name === 'html')
      //   return <div>{domToReact(domNode.children, options)} </div>;
      // if (domNode.name === 'head') return <></>;
      return domNode;
    },
    [
      content?.title,
      contentType,
      renderCover,
      renderInteractionDetails,
      renderLikes,
    ],
  );

  const renderContent = React.useMemo(() => {
    const options = {
      replace: covertHtmlToReact,
    };

    if (content?.htmlContent != null) {
      const parsed = parse(content?.htmlContent, options) as React.ReactElement<
        any,
        string | React.JSXElementConstructor<any>
      >;
      return renderToString(parsed).replaceAll('list_order', 'value');
    }

    return '';
  }, [content?.htmlContent, covertHtmlToReact]);

  return (
    <Card
      className="flex flex-col h-full  overflow-x-auto overflow-y-hidden"
      style={{ width: width * MAGNIFY, height: height * MAGNIFY }}
    >
      {content?.articleType === 'pdf' ? (
        <PdfPreview
          pdfUrl={content?.pdfContent}
          width={width * MAGNIFY}
          icon={contentType.icon}
          label={contentType.label}
          title={content?.title}
        />
      ) : (
        <iframe
          className="w-full h-full"
          srcDoc={renderContent}
          style={{
            width: '100%',
            height: '100%',
            border: 'none',
            overflow: 'hidden',
          }}
        />
      )}
    </Card>
  );
};

export default ArticlePreview;
