import * as React from 'react';
import useAppDispatch from '@data/useAppDispatch';
import { submitReviews } from '@data/timeline/timeline.thunk';

export const ReviewPostContext = React.createContext({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onApprove: (i: number) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onReject: (i: number) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onRevert: (i: number) => {}, // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSubmit: (i: number) => {},
});

const useReviewPosts = () => {
  const [reviews, setReviews] = React.useState<
    { status: 'accepted' | 'rejected'; id: number }[]
  >([]);
  const [review, setReview] = React.useState<
    { status: 'accepted' | 'rejected' | 'remove'; id: number } | undefined
  >(undefined);
  const [status, setStatus] = React.useState<
    'reviewing' | 'submitting' | 'pending'
  >('reviewing');

  const dispatch = useAppDispatch();

  const onApprove = React.useCallback(
    (id: number) => {
      setReview({ status: 'accepted', id });
    },
    [setReview],
  );

  const onReject = React.useCallback(
    (id: number) => {
      setReview({ status: 'rejected', id });
    },
    [setReview],
  );

  const onRevert = React.useCallback((id: number) => {
    setReview({ status: 'remove', id });
  }, []);

  React.useEffect(() => {
    if (review) {
      if (review.status === 'remove') {
        setReviews(reviews.filter((r) => r.id !== review.id));
      } else {
        setReviews([
          ...reviews,
          review as { status: 'accepted' | 'rejected'; id: number },
        ]);
      }
      setReview(undefined);
    }
  }, [review, reviews]);

  React.useEffect(() => {
    if (status === 'submitting') {
      dispatch(submitReviews({ reviews })).then(() => {
        setStatus('reviewing');
      });
      setStatus('pending');
    }
    if (status === 'pending') {
      setReviews([]);
    }
  }, [dispatch, reviews, status]);

  const onSubmit = React.useCallback(
    () => () => setStatus('submitting'),
    [setStatus],
  );

  const onInstanceSubmit = React.useCallback(
    () => (id: number) => {
      dispatch(submitReviews({ reviews: [{ id, status: 'accepted' }] })).then(
        () => {
          setStatus('reviewing');
        },
      );
      setStatus('pending');
    },
    [dispatch],
  );

  const providerValue = React.useMemo(
    () => ({
      onApprove,
      onReject,
      onRevert,
      onSubmit: onInstanceSubmit(),
    }),
    [onApprove, onInstanceSubmit, onReject, onRevert],
  );

  const Provider = ({ children }) => {
    return (
      <ReviewPostContext.Provider value={providerValue}>
        {children}
      </ReviewPostContext.Provider>
    );
  };

  const reset = React.useCallback(() => {
    setReviews([]);
  }, []);

  return {
    Provider,
    onSubmit,
    submitting: status !== 'reviewing',
    reviews,
    reset,
  };
};

export default useReviewPosts;
