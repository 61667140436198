import React from 'react';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import VideocamIcon from '@mui/icons-material/Videocam';
import { Button } from '@mui/material';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import { useFilePicker } from 'use-file-picker';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import {
  DraftContext,
  DraftShareActions,
} from '@shared/components/make-post/hooks/useDraftActions';
import MakePostAttachmentView from '@shared/components/make-post/components/make-post-editor/MakePostAttachmentView';
import {
  DefaultValue,
  DefaultValueContext,
} from '@shared/components/make-post/hooks/useDefaultValue';
import useIsMobile from '@hooks/use-breakpoint/useIsMobile';
import MultiFunctionButton from '../../../multi-functional-button/MultiFunctionButton';
import HDivider from '../../../divider/HDivider';
import { FeedPublishStatus } from '../../../../../../models/Feed';

const getActions = (
  onShare: () => void | Promise<void>,
  onStartScheduling: () => void,
  onDraft: () => void,
  pubStatus?: FeedPublishStatus,
) => {
  const mainButton = {
    disabled: false,
    // label: editMode ? 'SUBMIT' : 'SHARE',
    label: pubStatus ? 'UPDATE' : 'SUBMIT',
    action: onShare,
  };

  if (pubStatus === FeedPublishStatus.SCHEDULED) {
    mainButton.label = 'RESCHEDULE';
    mainButton.action = onStartScheduling;
  }

  if (pubStatus === FeedPublishStatus.DRAFT) {
    mainButton.label = 'UPDATE';
    mainButton.action = onDraft;
  }

  const extraButtons = pubStatus
    ? []
    : [
        {
          label: 'SAVE AS DRAFT',
          Icon: <SaveAsIcon />,
          action: onDraft,
        },
        {
          label: 'SCHEDULE POST',
          Icon: <ScheduleSendIcon />,
          action: onStartScheduling,
        },
      ];

  return {
    mainButton,
    extraButtons,
  };
};

const getAttachmentButtons = () => [
  {
    label: 'Photo',
    Icon: <CameraAltIcon />,
    type: 'image/.jpg,.jpeg,.png',
  },
  {
    label: 'Video',
    Icon: <VideocamIcon />,
    type: 'video/*',
  },
  {
    label: 'Emoji',
    Icon: <EmojiEmotionsIcon />,
    type: 'emoji',
  },
];

const MakePostFooter: React.FC<{
  defaultAttachmentSelected?: 'photo' | 'video';
  openShowEmojiMenu: () => void;
  handleCloseDialog: () => void;
}> = ({ openShowEmojiMenu, defaultAttachmentSelected, handleCloseDialog }) => {
  const { onShare, onStartScheduling, onDraft } = React.useContext(
    DraftContext,
  ) as DraftShareActions;

  const defaultValue = React.useContext(DefaultValueContext) as DefaultValue;

  const isMobile = useIsMobile();

  const actions = React.useMemo(
    () =>
      getActions(
        onShare,
        onStartScheduling as () => void,
        onDraft,
        defaultValue.pubStatus,
      ),
    [defaultValue, onShare, onStartScheduling, onDraft],
  );

  const [selectedFileType, setSelectedFileType] = React.useState({
    type: '',
    count: 0,
  });

  const [openFileSelector, { plainFiles, filesContent }] = useFilePicker({
    accept: selectedFileType.type,
    readAs: 'DataURL',
  });

  const [attachments, setAttachments] = React.useState<
    (
      | {
          file: File;
          content: string;
        }
      | string
    )[]
  >(defaultValue.attachments || []);

  React.useEffect(() => {
    setSelectedFileType((prev) => {
      if (!defaultAttachmentSelected) return prev;
      return {
        type:
          defaultAttachmentSelected === 'photo'
            ? 'image/.jpg,.jpeg,.png'
            : 'video/*',
        count: prev.count + 1,
      };
    });
  }, [defaultAttachmentSelected]);

  const removeAttachment = React.useCallback((index: number) => {
    setAttachments((prev) => {
      const newAttachments = [...prev];
      newAttachments.splice(index, 1);
      return newAttachments;
    });
  }, []);

  const attachmentButtons = React.useMemo(() => getAttachmentButtons(), []);

  const handleOnAttachmentClick = (type: string) => () => {
    if (type === 'emoji') openShowEmojiMenu();
    else setSelectedFileType({ type, count: selectedFileType.count + 1 });
  };

  React.useEffect(() => {
    if (selectedFileType.type !== '') openFileSelector();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFileType]);

  React.useEffect(() => {
    setAttachments((prev) => [
      ...prev,
      ...(plainFiles?.map((file, i) => ({
        file,
        content: filesContent[i].content,
      })) || []),
    ]);
  }, [plainFiles, filesContent]);

  const renderAttachmentButtons = () => {
    return (
      <div className="flex w-full sm:w-auto">
        {attachmentButtons?.map((a) => (
          <Button
            classes={{
              root: '!mr-2 !p-4',
              text: "!text-sm !font-normal !leading-5  !font-['Roboto'] !text-on-secondary !capitalize",
              startIcon: '!text-on-primary',
            }}
            startIcon={a.Icon}
            onClick={handleOnAttachmentClick(a.type)}
            key={`attachment-button-${a.label}`}
          >
            {a.label}
          </Button>
        ))}
      </div>
    );
  };

  return (
    <>
      <MakePostAttachmentView
        attachments={attachments}
        removeAttachment={removeAttachment}
      />
      <div className="flex flex-col items-center justify-start w-full h-auto pl-2 sm:flex-row sm:h-[76px] pr-[27px]">
        {renderAttachmentButtons()}
        <HDivider className="block sm:hidden" />
        <div className="flex flex-row justify-end flex-1 mt-3 mb-3 sm:mt-0 sm:mb-0">
          {isMobile && (
            <Button
              variant="outlined"
              onClick={handleCloseDialog}
              classes={{
                root: "!w-[115px] !h-10  !font-medium !text-sm !font-['Roboto'] mr-2",
              }}
            >
              DISCARD
            </Button>
          )}
          <MultiFunctionButton
            mainButton={actions.mainButton}
            extraButtons={actions.extraButtons}
          />
        </div>
      </div>
    </>
  );
};

export default MakePostFooter;
