// @flow
import * as React from 'react';
import { Card, IconButton } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { useSelector } from 'react-redux';
import { Content, ContentTypes } from '@data/contents/Contents.model';
import { ContentsSelectors } from '@data/contents/Contents.selectors';
import { getContent } from '@data/contents/Contents.thunk';
import useAppDispatch from '@data/useAppDispatch';
import LoadingPage from '@shared/components/loading-page';
import useContent from '@view/content-management-system/hooks/useContent';
import ArticlePreview from './ArticlePreview';
import { getContentTypeView } from '../../../logic/CMS/content.logic';

const SeriesPreview: React.FC<{
  content: Content;
  width: number;
  height: number;
  setSelectedArticle: (id: number) => void;
}> = ({ content, width, height, setSelectedArticle }) => {
  const { title, cover, steps, contentType, description } = useContent(content);
  const articleIcon = getContentTypeView(ContentTypes.ARTICLE);
  const [selectedStep, setSelectedStep] = React.useState(0);
  const selectedArticle = useSelector((state: any) =>
    ContentsSelectors.contents.selectById(
      state.contents,
      steps[selectedStep - 1]?.id || -1,
    ),
  );
  const dispatch = useAppDispatch();
  const MAGNIFY = 1;
  React.useEffect(() => {
    setSelectedArticle((steps[selectedStep - 1]?.id as number) || -1);
    if (selectedStep > 0 && !selectedArticle && steps[selectedStep - 1]?.id) {
      dispatch(
        getContent({
          id: steps[selectedStep - 1]?.id as string,
        }),
      );
    }
  }, [dispatch, selectedArticle, selectedStep, setSelectedArticle, steps]);

  const renderCover = () => {
    return <img src={cover} className="w-full h-auto" />;
  };

  const renderDetails = () => {
    return (
      <div className="flex flex-col items-center -mt-5 bg-white w-[557px] rounded-[50%] pt-[50px]">
        <div className="flex items-center justify-center w-full caption text-on-secondary">
          {contentType.icon('w-3 mr-2 ')}
          <span className="uppercase">{contentType.label}</span>
          <CircleIcon className="w-1 h-1 ml-2 mr-2" />
          {steps.length} steps
        </div>
        <h1 className="mt-3 text-center h1 text-on-primary max-w-[350px]">
          {title}
        </h1>
        <p className="mt-2 text-center body1 text-on-primary w-[287px]">
          {description}
        </p>
      </div>
    );
  };

  const renderSteps = () => {
    return (
      <div className="flex flex-col w-full mt-5">
        {steps.map((step, index) => {
          return (
            <IconButton
              key={`step-${index}`}
              className="flex flex-row w-full p-2 pt-2 pb-2 pl-4 rounded-none h-[93px]"
            >
              <img
                src={step.cover}
                className="object-cover mr-4 w-[78px] h-[78px] rounded-md"
              />
              <div className="flex flex-col-reverse w-full h-full">
                <div className="flex items-center justify-start mt-1 caption text-on-secondary">
                  {articleIcon.icon('h-4 w-4 mr-2')}
                  {articleIcon.label}
                </div>
                <h3 className="w-full text-left h3 text-on-primary ellipsis2">
                  {step.title}
                </h3>
              </div>
            </IconButton>
          );
        })}
      </div>
    );
  };

  const renderStepsView = () => {
    return (
      <Card
        className={`flex flex-col items-center h-full p-0 overflow-auto overflow-x-hidden w-[${
          width * MAGNIFY
        }px] max-h-[${height * MAGNIFY}px]`}
      >
        {renderCover()}
        {renderDetails()}
        {renderSteps()}
      </Card>
    );
  };

  const renderStepsIndicator = () => {
    return (
      <div className="flex flex-col mr-[50px]">
        {[...Array(steps.length + 1)].map((_, index) => {
          return (
            <Card
              key={`step-indicator-${index}`}
              className={`rounded-full ${
                selectedStep === index ? 'bg-accent' : 'bg-white'
              } flex-col-center  w-9 h-9 mb-[14px]`}
            >
              <IconButton
                className="w-full h-full text-black caption"
                onClick={() => setSelectedStep(index)}
              >
                {index === 0 ? 'M' : index}
              </IconButton>
            </Card>
          );
        })}
      </div>
    );
  };

  return (
    <div className="flex flex-row">
      {renderStepsIndicator()}
      {selectedStep === 0 && renderStepsView()}
      {selectedArticle && selectedStep > 0 && (
        <ArticlePreview
          content={selectedArticle}
          width={width}
          height={height}
        />
      )}
      {!selectedArticle && selectedStep > 0 && (
        <Card
          style={{ width: width * MAGNIFY, height: height * MAGNIFY }}
          className="flex flex-col items-center p-0 overflow-auto overflow-x-hidden"
        >
          <LoadingPage />
        </Card>
      )}
    </div>
  );
};

export default SeriesPreview;
