/**
 * Why are we using user_id and user as different params here?
 */
export enum ApiEndpoint {
  LOGIN = 'users/login',
  LOGOUT = 'users/logout',
  FORGOT_PASSWORD = 'reset',
  API_INIT = 'auth/api_key/init',
  REFRESH_TOKEN = 'auth/api_key/renew',
  TIMELINE = 'users/:user/timeline/t/:type/p/:page',
  ASSETS = 'users/:user/assets/:category',
  ACTION_ON_ASSETS = 'users/:user/assets/share/:feedID/:action',
  GET_CONTACTS = 'users/:user/contact/t/:type/p/:page',
  GET_FRIENDS = 'users/:user/contact/t/friends/p/:page',
  PROFILE = 'users/:user/profile',
  CONDITIONS_LABEL = 'users/:user/condition_labels',
  ROLES = 'roles',
  EXTERNAL_PROFILE = 'users/:user/profile/external_view/full',
  TAGS = 'tags',
  USER_TAGS = 'users/:user/interest/c/:category',
  AGGREGATED_PROFILE = 'users/:user_id/aggregated_profile',
  POST_COMMENT = 'users/:user/assets/comment',
  GET_CHAT_HISTORY = 'users/:user/message/chat_list',
  GET_MESSAGES = 'users/:user/message/:peer/p/:page',
  GET_MESSAGES_SINCE = 'users/:user/message/:peer/since/:time',
  SEND_MESSAGE = 'users/:user/message/:peer',
  SUBSCRIBE_USER = 'users/:user/set_notification_token',
  ENCRYPT_MESSAGE_PATH = 'encrypt',
  USER_REFRESH_TOKEN = 'users/:user_id/renew',
  GET_PROFILE_PIC = 'users/:user/profile_pic/:targetId',
  // GET_PROFILE_PIC = 'https://webdev.curatio.me/profile-pic',
  SEARCH_FRIEND = 'users/:user/list/search/t/friends',
  CONFIRM_MESSAGE = 'users/:user/message/:peer/confirm_last_seen_message/:messageId',
  GET_POST = 'asset/:id',
  GET_COMMENTS = 'asset/:parent/children',
  EDIT_ASSET = 'users/:user/assets/:category/:id',
  DELETE_ASSET = 'users/:user/assets/:category/:id',
  ADMIN_FEED = 'users/:user/admin_feed/t/:type/p/:page',
  NOTIFICATIONS_LIST = 'users/:user/notification/t/:type',
  READ_NOTIFICATIONS = 'users/:user/notification/confirm_last_notification/:notification',
  FETCH_ALL_GROUPS = 'users/:user/support_groups/list_aggregate',
  FETCH_JOINABLE_GROUPS = 'users/:user/support_group/joinable/p/:page',
  FETCH_MY_GROUPS = 'users/:user/support_group/p/:page',
  FETCH_GROUP_MEMBERS = 'users/:user/support_groups/:groupId/members/p/:page',
  GROUP_REVIEW_FEED = '/users/:user/support_group_request/p/:page',
  UPDATE_PARTNER_CASE_ID = 'users/:user/pharmaco_vigilance/:case_id',
  AE_REVIEWED_FEED = 'users/:user/pharmaco_vigilance/cases/:month/:year/p/:page',
  FETCH_AE_LIST = 'users/:user/pharmaco_vigilance/manual_review_list/:category/p/:page',
  FETCH_AE_POSTS = 'users/:user/pharmaco_vigilance/manual_review_list/posts/p/:page',
  FETCH_AE_COMMENTS = 'users/:user/pharmaco_vigilance/manual_review_list/comments/p/:page',
  ACCEPT_AE = 'users/:user/pharmaco_vigilance/:assetId',
  REJECT_AE = 'users/:user/pharmaco_vigilance/mark_as_reviewed/:assetId',
  SHOW_PV = 'users/:user/pharmaco_vigilance/:assetId',
  SERVICE_KEY = 'info/supplier_key/:service',
  EMBED_ROCKS = 'https://api.embed.rocks/api',
  APPROVE_ASSET = 'users/:user/approve_asset/:asset_id',
  REVIEW_GROUP = 'users/:user/support_group_request/review/:id',
  CREATE_GROUP = 'users/:user/support_group_request',
  UPDATE_GROUP = 'users/:user/support_groups/:groupId',
  JOIN_OR_LEAVE_GROUP = 'users/:user/support_groups/:groupId',
  GET_USER_ACTIVITIES = 'users/:user/channel/feed/:groupId/p/:page',
  HIDE_GROUP = 'users/:user/support_groups/:groupId/suspend',
  UNHIDE_GROUP = 'users/:user/support_groups/:groupId/resume',
  DELETE_GROUP = 'users/:user/support_group/:groupId',
  GET_GROUP = 'support_group/:groupId',
  REVIEW_ASSET = '/users/:user/flagged_asset/:asset_id/:review_action',
  GET_CONTENT_CATEGORIES = 'content_category/list_categories',
  GET_CONTENT_TAGS = 'tags',
  UPDATE_PROFILE = 'users/:user/profile',
  CREATE_CONTENT_CATEGORY = '/content_categories',
  UPDATE_CONTENT_CATEGORY = 'content_category/:categoryId',
  DELETE_CONTENT_CATEGORY = 'content_category/:categoryId',
  DELETE_CONTENT = 'content_item/:id',
  UNPUBLISH_CONTENT = 'content_item/:id/unpublish',
  PUBLISH_CONTENT = 'content_item/:id/publish',
  GET_CONTENT_LIST_BY_TYPE = 'content_items/:pubStatus',
  GET_ALL_CONTENT_LIST = 'content_items/all',
  GET_CONTENT_DETAIL = 'content_item/:id',
  CREATE_EVENT = 'users/:user/social_event',
  EDIT_EVENT = 'users/:user/social_event/:eventId',
  CONTENT_ITEMS = 'content_items',
  UPDATE_CONTENT_ITEMS = 'content_item/:id',
  JOIN_EVENT = 'users/:user/social_event/:eventId/join',
  LEAVE_EVENT = 'users/:user/social_event/:eventId/leave',
  GET_EVENTS = 'users/:user/social_events/:eventType/p/:page',
  GET_SELECTED_EVENT = 'users/:user/social_event/:eventId',
  GET_EVENT_MEMBERS = 'users/:user/social_event/:eventId/members/p/:page',
  DELETE_EVENT = 'users/:user/social_event/:eventId',
  CREATE_CONTENT_TAG = 'tag',
  UPDATE_CONTENT_TAG = 'tag/:tagId',
  DELETE_CONTENT_TAG = 'tag/:tagId',
  UPLOAD_IMAGE = 'public_upload_attachment',
  RESET_PASSWORD = 'users/change_password',
  APP_INFO = '/info/app_info',
  VOTE_POLL = 'users/:user/poll/:pollId/vote',
  CREATE_POLL = 'users/:user/poll',
  EDIT_POLL = 'users/:user/poll/:pollId',
  GET_REACTIONS = 'users/:user/asset/:feedId/action/reactions/users/p/:page',
  MARK_CONTENT = 'content_item/:id/rating',
  GET_CONTENTS_BY_CATEGORY = 'content_categories_v5',
  BOOKMARK_CONTENT = 'content_item/:id/bookmark',
  START_SERIES = 'content_item/:id/start_series',
  MOVE_TO_NEXT_STEP = 'content_item/:id/move_next_step',
  MARK_SERIES_AS_COMPLETED = 'content_item/:id/mark_as_completed',
}

export enum ApiParam {
  USER = 'user',
  USER_ID = 'user_id',
  TYPE = 'type',
  PAGE = 'page',
  CATEGORY = 'category',
  FEED_ID = 'feedID',
  ASSET_ID = 'assetId',
  ACTION = 'action',
  NOTIFICATION_ID = 'notificationID',
  GROUP_ID = 'groupId',
  EVENT_ID = 'eventId',
  EVENT_TYPE = 'eventType',
  PUB_STATUS = 'pubStatus',
  CATEGORY_ID = 'categoryId',
}

export type ApiParams = Partial<Record<ApiParam, string | number>>;

export interface AuthParams {
  authentication_token: string;
  device_id: string;
}

export interface ApiError {
  errors?: {
    code: number;
    message: string;
    description: string;
  };
}
